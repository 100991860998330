import React, { useEffect, useState } from 'react';
import TopBar from './TopBar';
import SideBar from './SideBar';
import OrderHistory from '../OrderHistory/OrderHistory';
import AccountInfo from '../../Account/Main/AccountInfo';
import { useNavigate, useLocation } from 'react-router-dom';
import StoreCreditZone from '../Points/StoreCreditZone';
import LoyaltiPointZone from '../Points/LoyaltiPointZone';
import { useMediaQuery } from '@mui/material';
import Footer from '../../MainHome/Footer';
import { useSelector } from 'react-redux';
const MainOrder = ({ visible }) => {
  const Navigate = useNavigate();
  const { UserData } = useSelector((state) => state.authUser);
  const location = useLocation();
  const mymerchantId = location.state?.merchantId || '';
  const orderMethod = location.state?.orderMethod || '';

  // ;
  const isMobileNav = useMediaQuery('(max-width:768px)');
  const [navBar, setNavBar] = useState(!isMobileNav);
  const [isOvelay, setIsOvelay] = useState(false);
  const currentPath = window.location.pathname;

  useEffect(() => {
    if (
      !UserData &&
      currentPath == '/order/profile' &&
      UserData == 'undefined' &&
      UserData == null
    ) {
      Navigate(-1);
    }
  }, []);

  // useEffect(() => {
  //   const handleResize = () => {

  //     setNavBar(window.innerWidth >= 767);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  useEffect(() => {
    setNavBar(!isMobileNav);
    if (!isMobileNav) {
      setIsOvelay(false);
    }
  }, [isMobileNav]);
  useEffect(() => {
    if (isMobileNav) {
      navBar
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    }
  }, [navBar]);

  const handleNavClick = () => {
    if (isMobileNav) {
      setNavBar(!navBar);
    }
  };

  const handleOverlayClickOpen = () => {
    setIsOvelay(true);
  };
  const handleOverlayClickClose = () => {
    setIsOvelay(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [visible]);

  return (
    <>
      <div>
        <TopBar
          navBar={navBar}
          handleNavClick={handleNavClick}
          isOvelay={isOvelay}
          handleOverlayClickClose={handleOverlayClickClose}
          handleOverlayClickOpen={handleOverlayClickOpen}
          mymerchantId={mymerchantId}
          orderMethod={orderMethod}
        />
        <div className="side-bar-and-order-section"
          style={{minHeight:'calc(100vh - 150px)'}}
        >
          <SideBar
            navBar={navBar}
            setNavBar={setNavBar}
            handleNavClick={handleNavClick}
            handleOverlayClickClose={handleOverlayClickClose}
            mymerchantId={mymerchantId}
            orderMethod={orderMethod}
          />
          {visible === 'orderhistory' && (
            <OrderHistory
              mymerchantId={mymerchantId}
              orderMethod={orderMethod}
            />
          )}
          {visible === 'profile' && <AccountInfo mymerchantId={mymerchantId} />}
          {visible === 'StoreCreditZone' && (
            <StoreCreditZone
              mymerchantId={mymerchantId}
              orderMethod={orderMethod}
            />
          )}
          {visible === 'LoyaltyPointZone' && (
            <LoyaltiPointZone
              mymerchantId={mymerchantId}
              orderMethod={orderMethod}
            />
          )}
        </div>
        <div className="side-bar-and-order-section-footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MainOrder;
