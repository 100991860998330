import React, { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, TextField } from '@mui/material';
import CouponApplyIcon from '../../../Assets/Payment/coupon-apply.svg';
import { fetchCouponListData } from '../../../redux/features/Coupon/couponListSlice';
import { useSelector, useDispatch } from 'react-redux';
import Validation from '../../../Constants/Validation';
import DeliveryAddressIcon from '../../../Assets/Payment/DeliveryAddress.svg';
import {
  fetchCouponData,
  removeCoupon,
} from '../../../redux/features/Coupon/couponSlice';
import CouponTipSectionLogic from './CouponTipSectionLogic';

const ApplyCouponModal = ({
  merchantId,
  couponSelected,
  values,
  setValues,
}) => {
  const dispatch = useDispatch();
  const [allCouponData, setAllCouponData] = useState([]);
  const [selectedCoupon, setselectedCoupon] = useState('');
  const [activeCheckBox, setActiveCheckBox] = useState('');
  const [couponNotSelected, setCouponNotSelected] = useState(false);
  const { getCurrentDateTime, convertToIndianTime, formatDate } = Validation();
  const couponListDataState = useSelector((state) => state.couponList);
  const authUserData = useSelector((state) => state.authUser);
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCouponNotSelected(false);
    setselectedCoupon('');
    setActiveCheckBox('');
  };

  const handleOpenApplyCouponButton = () => {
    // console.log('values ',values)
    if (values.usedLoyaltyPoint === '' && values.usedStoreCredit === '') {
      setOpen(true);
    } else if (values.loyaltyPoint !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: { coupon: 'please remove loyality point to access couopns' },
      }));
    } else if (values.storeCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: { coupon: 'please remove storeCredit point to access couopns' },
      }));
    }
  };
  const customerId = useMemo(() => {
    return authUserData.isAuthenticated &&
      authUserData.UserData &&
      authUserData.UserData.id
      ? authUserData.UserData.id
      : '';
  }, [authUserData]);

  const { handleRemoveCoupon } = CouponTipSectionLogic({ merchantId });

  const handleCouponChange = (checkboxId, checkboxName) => {
    if (checkboxName == selectedCoupon) {
      setselectedCoupon('');
      setActiveCheckBox('');
    } else {
      setselectedCoupon(checkboxName);
      setActiveCheckBox(checkboxId);
      setCouponNotSelected(false);
    }
  };
  const current_date_time = getCurrentDateTime();

  const handleSelectCouponFromLIst = () => {
    if (selectedCoupon) {
      const data = {
        coupon: selectedCoupon,
        merchant_id: merchantId,
        current_date_time: current_date_time,
        customer_id: customerId,
      };
      dispatch(fetchCouponData(data));
      handleClose();
    } else {
      setCouponNotSelected(true);
      handleRemoveCoupon();
    }
  };

  const data = {
    merchant_id: merchantId,
    current_date_time: current_date_time,
  };
  useEffect(() => {
    dispatch(fetchCouponListData(data));
  }, [merchantId]);

  useEffect(() => {
    if (
      !couponListDataState.loading &&
      couponListDataState.couponListData &&
      couponListDataState.couponListData.result
    ) {
      setAllCouponData(couponListDataState.couponListData.result);
    }
  }, [couponListDataState, couponListDataState.loading]);

  useEffect(() => {
    setselectedCoupon(couponSelected);
  }, [couponSelected]);

  return (
    <>
      <div>
        {/* <div className="apply-coupon-modal-button" onClick={handleOpen}>
                    <div>
                        <p>Apply a Coupon</p>
                        <span>To Get Attractive Discounts & Benefits</span>
                    </div>
                    <img src={ApplyCouponIcon} width={20} alt="apply-coupon" />
                </div> */}

        <div
          className="apply-coupon-listing-btn"
          //  onClick={handleOpen}
          onClick={handleOpenApplyCouponButton}
        >
          <p>
            View All Coupons <img src={CouponApplyIcon} alt="coupon-apply" />{' '}
          </p>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="basic-modal" style={{ maxHeight: 'max-content' }}>
            <div id="modal-modal-title">
              <span>Apply Coupon</span>
              <CloseIcon onClick={handleClose} className="modal-close-icon" />
            </div>

            <form
              id="modal-modal-description"
              className="modal-forms"
              style={{ maxHeight: '65vh', overflowY: 'auto' }}
            >
              {/* <FormControl>
                <div className="modal-form-single-item">
                  <TextField
                    label=" Enter Coupon Code "
                    className="modal-form-single-input "
                    variant="filled"
                    size="small"
                    autoComplete="off"
                    type="text"
                  ></TextField>
                  <span className="input-error">{ values.errors.oldpassword}</span> 
                  <span className="show-hide-button"> Check </span>
                </div>
              </FormControl> */}
              {/* 
              {allCouponData &&
                Array.isArray(allCouponData) &&
                allCouponData.length >= 1 &&
                allCouponData.map((item, index) => (
                  <div
                    key={index}
                    className="available-coupons-listing"
                    style={
                      selectedCoupon === item.name
                        ? { opacity: '1' }
                        : {
                            opacity: '1',
                            borderBottom:
                              allCouponData.length === index - 1
                                ? '1px solid #EBEBEB'
                                : 'none',
                          }
                    }
                  >
                    <div className="available-coupons-listing-checkbox">
                      <input
                        type="checkbox"
                        className="available-coupons-checkbox"
                        name="coupon"
                        id={item.id}
                        value={item.name}
                        checked={selectedCoupon === item.name}
                        onChange={(e)=>{handleCouponChange(item.id,item.name)}}  
                        style={{height:"20px",width:"20px", transform: "translate(7px, 9px)"}} 
                      />
                      <span className="available-coupons-checkbox-checkmark"></span>
                    </div>
                    <label
                      className="available-coupons-listing-content"
                      htmlFor={item.id}
                    >
                      <span style={{ color: '#0A64F9', fontSize: '16px' }}>
                        {item.name}
                      </span>{' '}
                      <br />
                      <span>
                        {item.flag === '0' ? `${item.discount}% ` : `$${item.discount}`} OFF on minimum order amount of ${item.min_amount}
                      </span>
                      <p>{item.flag === '0' ? `Maximum Discount Amount $${item.maximum_discount}` : ""}</p>
                      <p> Valid Date & Time(s) </p>
                      <p>
                        From {formatDate(item.date_valid)} -
                        {' ' +
                          convertToIndianTime(
                            item.time_valid,
                          ).toUpperCase()}{' '}
                        to
                        {' ' + formatDate(item.date_expire)} -
                        {' ' +
                          convertToIndianTime(item.time_expire).toUpperCase()}
                      </p>
                    </label>
                  </div>
                ))}
                 */}

              {allCouponData &&
              Array.isArray(allCouponData) &&
              allCouponData.length >= 1 ? (
                allCouponData.map((item, index) => (
                  <div
                    key={index}
                    className="available-coupons-listing"
                    style={
                      selectedCoupon === item.name
                        ? { opacity: '1' }
                        : {
                            opacity: '1',
                            borderBottom:
                              allCouponData.length === index - 1
                                ? '1px solid #EBEBEB'
                                : 'none',
                          }
                    }
                  >
                    <div className="available-coupons-listing-checkbox">
                      <input
                        type="checkbox"
                        className="available-coupons-checkbox"
                        name="coupon"
                        id={item.id}
                        value={item.name}
                        checked={selectedCoupon === item.name}
                        onChange={(e) => {
                          handleCouponChange(item.id, item.name);
                        }}
                        style={{
                          height: '20px',
                          width: '20px',
                          transform: 'translate(7px, 9px)',
                        }}
                      />
                      <span className="available-coupons-checkbox-checkmark"></span>
                    </div>
                    <label
                      className="available-coupons-listing-content"
                      htmlFor={item.id}
                    >
                      <span style={{ color: '#0A64F9', fontSize: '16px' }}>
                        {item.name}
                      </span>{' '}
                      <br />
                      <span>
                        {item.flag === '0'
                          ? `${item.discount}% `
                          : `$${item.discount}`}{' '}
                        OFF on minimum order amount of ${item.min_amount}
                      </span>
                      <p>
                        {item.flag === '0'
                          ? `Maximum Discount Amount $${item.maximum_discount}`
                          : ''}
                      </p>
                      <p> Valid Date & Time(s) </p>
                      <p>
                        From {formatDate(item.date_valid)} -
                        {' ' +
                          convertToIndianTime(
                            item.time_valid,
                          ).toUpperCase()}{' '}
                        to
                        {' ' + formatDate(item.date_expire)} -
                        {' ' +
                          convertToIndianTime(item.time_expire).toUpperCase()}
                      </p>
                    </label>
                  </div>
                ))
              ) : (
                <div className="delivery-address-not-selected">
                  <div className="not-select-empty-delivery-content">
                    <img src={DeliveryAddressIcon} alt="No-coupon" />
                    <h1> No coupons available</h1>
                  </div>
                </div>
              )}
            </form>
            {couponNotSelected ? (
              <span className="input-error coupon-error-on-modal">
                No Coupon Selected
              </span>
            ) : (
              ''
            )}
            <div
              className="modal-footer"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                padding: '1rem',
              }}
            >
              <Button
                className="modal-cancle-btn"
                onClick={handleClose}
                style={{
                  background: '#ffffff',
                  border: '1px solid #232323',
                  borderRadius: '6px',
                  color: '#232323',
                  textTransform: 'capitalize',
                  fontFamily: 'QuicksandBold, Quicksand',
                  flex: 1, // Grow to occupy available space
                  marginRight: '0.5rem', // Add margin between buttons
                }}
              >
                Cancel
              </Button>
              {allCouponData &&
                Array.isArray(allCouponData) &&
                allCouponData.length >= 1 && (
                  // Render the Apply button only if there are available coupons
                  <Button
                    className="modal-save-btn"
                    style={{
                      background: '#0a64f9',
                      border: '2px solid #0a64f9',
                      borderRadius: '6px',
                      color: '#ffffff',
                      textTransform: 'capitalize',
                      fontFamily: 'QuicksandBold, Quicksand',
                      flex: 1, // Grow to occupy available space
                      marginLeft: '0.5rem', // Add margin between buttons
                    }}
                    onClick={handleSelectCouponFromLIst}
                  >
                    Apply
                  </Button>
                )}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ApplyCouponModal;
