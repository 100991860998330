import { useState } from 'react';
import axios from 'axios';
import {
  BASE_URL_SANDBOX,
  SAVE_ORDER,
  GET_ALL_CART_PRODUCT,
} from '../Constants/Config';
import { useSelector } from 'react-redux';
const Validation = () => {
  const { isGuest } = useSelector((state) => state.authUser);
  const EmailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const regex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
  const PassNum = /\d/i;
  const PassUppercase = /^(?=.*[A-Z])/;
  const PassLowercase = /^(?=.*[a-z])/;
  const PassSpecCha = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/i;
  const emoji =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}]/gu;

  const PassLeng = /^.{6,}$/i;
  const Space = /^[^\s]+$/i;
  const PhoneNo = /^\d{10}$/;
  const Nameval = /^[a-zA-Z]+$/;
  const Numberval = /^\d*$/;
  const Characterval = /^[A-Za-z\s]+$/;

  const validateEmail = (email, updatedErrors) => {
    if (email === '') {
      updatedErrors.username = 'Please enter email';
    } else if (emoji.test(email)) {
      updatedErrors.username = 'Emoji not allowed';
    } else if (!EmailReg.test(email)) {
      updatedErrors.username = 'Please enter valid email';
    } else {
      updatedErrors.username = '';
    }
    // return updatedErrors;
  };
  const validatePassword = (password, updatedErrors) => {
    if (password === '') {
      updatedErrors.password = 'Please enter password';
    } else if (emoji.test(password)) {
      updatedErrors.password = 'Emoji not allowed';
    } else if (!PassUppercase.test(password)) {
      updatedErrors.password = 'Password should contain Uppercase letter';
    } else if (!PassLowercase.test(password)) {
      updatedErrors.password = 'Password should contain Lowercase letter';
    } else if (!PassNum.test(password)) {
      updatedErrors.password = 'Password should contain number';
    } else if (!PassSpecCha.test(password)) {
      updatedErrors.password = 'Password should contain Special Character';
    } else if (!PassLeng.test(password)) {
      updatedErrors.password = 'Length should be greater or equal to 6';
    } else if (!Space.test(password)) {
      updatedErrors.password = 'Space is not allow';
    } else {
      updatedErrors.password = '';
    }
  };

  const validatePasswordBlank = (password, updatedErrors) => {
    if (password === '') {
      updatedErrors.password = 'Please enter password';
    } else if (emoji.test(password)) {
      updatedErrors.password = 'Emoji not allowed';
    } else if (!Space.test(password)) {
      updatedErrors.password = 'Space is not allow';
    } else {
      updatedErrors.password = '';
    }
  };

  const validateOldPassword = (password, updatedErrors) => {
    if (password === '') {
      updatedErrors.oldpassword = 'Please enter old password';
    } else if (emoji.test(password)) {
      updatedErrors.oldpassword = 'Emoji not allowed';
    } else {
      updatedErrors.oldpassword = '';
    }
  };
  const validatePhoneNumber = (phone, updatedErrors) => {
    if (phone === '') {
      updatedErrors.phone = 'Please enter phone number';
    } else if (emoji.test(phone)) {
      updatedErrors.phone = 'Emoji not allowed';
    } else if (!PhoneNo.test(phone)) {
      updatedErrors.phone = 'Phone no not valid';
    } else {
      updatedErrors.phone = '';
    }
  };
  const validateCaptcha = (captcha, updatedErrors) => {
    if (captcha === '') {
      updatedErrors.recaptcha = 'Please check captcha';
    } else if (captcha === false) {
      updatedErrors.recaptcha = 'Please check captcha';
    } else {
      updatedErrors.recaptcha = '';
    }
  };

  const validateConfrimPassword = (
    confrimpassword,
    password,
    myconfString,
    updatedErrors,
  ) => {
    if (confrimpassword === '') {
      updatedErrors.confirmpassword = `Please enter ${myconfString}`;
    } else if (emoji.test(confrimpassword)) {
      updatedErrors.confirmpassword = 'Emoji not allowed';
    } else if (confrimpassword !== password) {
      updatedErrors.confirmpassword = 'Not matching with password';
    } else {
      updatedErrors.confirmpassword = '';
    }
  };
  const validateFirstName = (fname, updatedErrors) => {
    if (fname === '') {
      updatedErrors.firstname = 'Please enter FirstName ';
    } else if (emoji.test(fname)) {
      updatedErrors.firstname = 'Emoji not allowed';
    } else if (!Space.test(fname)) {
      updatedErrors.firstname = 'Space is not allow';
    } else if (!Nameval.test(fname)) {
      updatedErrors.firstname = 'Name only contain alphabet';
    } else {
      updatedErrors.firstname = '';
    }
  };
  const validateLastName = (lname, updatedErrors) => {
    if (lname === '') {
      updatedErrors.lastname = 'Please enter LastName ';
    } else if (emoji.test(lname)) {
      updatedErrors.lastname = 'Emoji not allowed';
    } else if (!Space.test(lname)) {
      updatedErrors.lastname = 'Space is not allow';
    } else if (!Nameval.test(lname)) {
      updatedErrors.lastname = 'Name only contain alphabet';
    } else {
      updatedErrors.lastname = '';
    }
  };

  const validateStreetAddress = (street, updatedErrors) => {
    if (street === '') {
      updatedErrors.streetaddress = 'Please enter street address ';
    } else if (emoji.test(street)) {
      updatedErrors.streetaddress = 'Emoji not allowed';
    } else {
      updatedErrors.streetaddress = '';
    }
  };
  const validateState = (state, updatedErrors) => {
    if (state === '') {
      updatedErrors.state = 'Please enter state ';
    } else if (emoji.test(state)) {
      updatedErrors.state = 'Emoji not allowed';
    } else {
      updatedErrors.state = '';
    }
  };
  const validateIdNumber = (idnumber, updatedErrors) => {
    if (idnumber === '') {
      updatedErrors.idnumber = 'Please enter ID number ';
    } else if (emoji.test(idnumber)) {
      updatedErrors.idnumber = 'Emoji not allowed';
    } else {
      updatedErrors.idnumber = '';
    }
  };
  const formatCreditCardNumber = (inputNumber) => {
    // Remove any existing spaces
    let cleanedNumber = inputNumber.replace(/\s/g, '');

    // Use regex to add spaces every 4 digits
    let formattedNumber = cleanedNumber.replace(/(\d{4})/g, '$1 ');

    // Trim any leading or trailing spaces
    formattedNumber = formattedNumber.trim();

    return formattedNumber;
  };

  const validateCardNumber = (cardnumber, updatedErrors) => {
    if (cardnumber === '') {
      updatedErrors.cardnumber = 'Please enter Card Number';
    } else if (cardnumber.length < 13 || cardnumber.length > 16) {
      updatedErrors.cardnumber = 'Card number must be between 13 and 16 digits';
    } else if (emoji.test(cardnumber)) {
      updatedErrors.cardnumber = 'Emoji not allowed';
    } else {
      updatedErrors.cardnumber = '';
    }
  };

  const validateCvv = (cvv, updatedErrors) => {
    if (cvv === '') {
      updatedErrors.cvv = 'Please enter CVV';
    } else if (cvv.length !== 3 && cvv.length !== 4) {
      updatedErrors.cvv = 'CVV must be either 3 or 4 digits';
    } else if (emoji.test(cvv)) {
      updatedErrors.cvv = 'Emoji not allowed';
    } else {
      updatedErrors.cvv = '';
    }
  };

  const validateApartment = (apartment, updatedErrors) => {
    if (apartment === '') {
      updatedErrors.apartmentnumber = 'Please enter Suite/Apartment Number ';
    } else if (emoji.test(apartment)) {
      updatedErrors.apartmentnumber = 'Emoji not allowed';
    } else {
      updatedErrors.apartmentnumber = '';
    }
  };

  const validateCity = (city, updatedErrors) => {
    if (city === '') {
      updatedErrors.city = 'Please enter city ';
    } else if (emoji.test(city)) {
      updatedErrors.city = 'Emoji not allowed';
    } else if (!Characterval.test(city)) {
      updatedErrors.city = 'City  contain character';
    } else {
      updatedErrors.city = '';
    }
  };

  const validateZipCode = (zipcode, updatedErrors) => {
    if (zipcode === '') {
      updatedErrors.zipcode = 'Please enter zipcode  ';
    } else if (emoji.test(zipcode)) {
      updatedErrors.zipcode = 'Emoji not allowed';
    } else if (zipcode?.length !== 5) {
      updatedErrors.zipcode = 'Max 5 number enter';
    } else if (!Numberval.test(zipcode)) {
      updatedErrors.zipcode = 'Zipcode contain number ';
    } else {
      updatedErrors.zipcode = '';
    }
  };
  const validateIDproof = (idproof, updatedErrors) => {
    if (idproof === '') {
      updatedErrors.idproof = 'Please enter ID  ';
    } else {
      updatedErrors.idproof = '';
    }
  };

  function validateDateOfBirth(dateString) {
    // Parse the input date string
    const dob = new Date(dateString);

    // Check if the input is a valid date
    if (isNaN(dob.getTime())) {
      return false;
    }

    // Calculate the minimum allowed date (21 years ago from today)
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 21);

    // Check if the date of birth is at least 21 years old and not in the future
    return dob <= minDate && dob <= new Date();
  }

  const validateDOB = (DOB, updatedErrors) => {
    //console.log(DOB);
    if (DOB === '' || DOB === 'NaN-aN-aN') {
      updatedErrors.DOB = '';
    } else if (!validateDateOfBirth(DOB)) {
      updatedErrors.DOB = 'Eligibility 21 year old only ';
    } else {
      updatedErrors.DOB = '';
    }
  };

  const validateExpiredate = (expiredate, updatedErrors) => {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const timestampExpdate = Date.parse(expiredate);
    // Check if the selected date is after today
    const isDateValid = timestampExpdate > timestamp;
    // console.log(isDateValid)
    // console.log("expiredate: " + timestampExpdate)
    // console.log("current: " + timestamp)
    if (expiredate === '') {
      updatedErrors.expiredate = 'Please enter Expiration Date  ';
    } else if (!isDateValid) {
      updatedErrors.expiredate = 'Expiration Date cannot be greater than today';
    } else {
      updatedErrors.expiredate = '';
    }
  };

  const validateCardExpiredate = (expiredate, updatedErrors) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index

    const expiryDate = new Date(expiredate);
    const expiryYear = expiryDate.getFullYear();
    const expiryMonth = expiryDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index

    // Calculate the next month's year and month
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
    const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;

    // Check if the expiry year and month are greater than or equal to the next month's year and month
    const isValid =
      expiryYear > nextYear ||
      (expiryYear === nextYear && expiryMonth >= nextMonth);

    if (expiredate === '') {
      updatedErrors.expiredate = 'Please enter Expiration Date';
    }
    // else if (!isValid) {
    //   updatedErrors.expiredate = 'Expiration Month should be next month';
    // }
    else {
      updatedErrors.expiredate = '';
    }
  };

  const validateImageProof = (myfile, updatedErrors) => {
    if (myfile === '') {
      // console.log(myfile)
      updatedErrors.myfile = 'Please select image';
    } else {
      updatedErrors.myfile = '';
    }
  };

  const validateCoupon = (coupon, updatedErrors) => {
    // if (coupon.length > 11) {
    //   updatedErrors.coupon = 'Length should less than 10';
    // } else
    if (regex.test(coupon)) {
      updatedErrors.coupon = ' Special character not allow';
    } else {
      updatedErrors.coupon = '';
    }
  };

  const convertToIndianTime = (usTime) => {
    if (usTime) {
      // Split the input time into hours and minutes
      const [hours, minutes] = usTime.split(':');

      // Create a Date object with the current date and the input hours and minutes
      const usDate = new Date();
      usDate.setHours(parseInt(hours, 10));
      usDate.setMinutes(parseInt(minutes, 10));

      // Convert the Date object to Indian time
      const options = {
        timeZone: 'Asia/Kolkata', // Indian Standard Time (IST) timezone
        hour12: true, // Use 12-hour format (true) or 24-hour format (false)
        hour: 'numeric', // Display hours
        minute: 'numeric', // Display minutes
      };

      const indianTime = usDate.toLocaleTimeString('en-IN', options);
      return indianTime;
    }
  };

  function getImageExtension(base64String) {
    // Extract the mime type from the base64 string
    const mimeType = base64String.match(
      /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,(.*)$/,
    );

    if (mimeType && mimeType[1]) {
      // Extract the file extension from the mime type
      const extension = mimeType[1].split('/').pop();
      return extension;
    }

    return null; // Unable to determine the extension
  }

  const formatExireDate = (originalDateString) => {
    // Parse the original date string
    var originalDate = new Date(originalDateString);

    // Create a new date with the desired format
    var formattedDate =
      originalDate.getFullYear() +
      '-' +
      ('0' + (originalDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + originalDate.getDate()).slice(-2);
    return formattedDate;
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    const year = date.getFullYear().toString().slice(2); // Extract the last two digits of the year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${day}/${month}/${year}`;
  };

  const percentOffItem = (price, compare_price) => {
    const percent = 100 - (parseFloat(price) / parseFloat(compare_price)) * 100;
    if (percent > 0) return parseInt(percent);
    else return 0;
  };
  const AddtoCart = (product, merchantId) => {
    const cartKey = `cart_${merchantId}`;
    let cartData = JSON.parse(localStorage.getItem(cartKey)) || [];
    cartData.push(product);

    localStorage.setItem(cartKey, JSON.stringify(cartData));
  };

  const checkInCart = (product, merchantId) => {
    const cartKey = `cart_${merchantId}`;
    let cartData = JSON.parse(localStorage.getItem(cartKey)) || [];

    const productExists = cartData.some((item) => {
      const sameProductId = item.productId === product.productId;
      // const sameCategoryId = item.categoryId === product.categoryId;

      // Check if all variants match
      if (product.varients) {
        const sameVariants = product.varients.every((pVariant) => {
          // Find a matching variant in the cart item
          const matchingVariant = item.varients.find((variant) => {
            return (
              pVariant.varientName === variant.varientName &&
              pVariant.varientVl === variant.varientVl
            );
          });
          // If a matching variant is found for each pVariant, it means all variants match
          return !!matchingVariant;
        });

        // return sameProductId && sameCategoryId && sameVariants;
        return sameProductId && sameVariants;
      } else {
        return sameProductId;
      }
    });

    return productExists;
  };

  const UpdateQuantity = (productId, merchantId, categoryId, qty) => {
    const cartKey = `cart_${merchantId}`;
    let cartData = JSON.parse(localStorage.getItem(cartKey)) || [];

    const updatedCartData = cartData.map((item) => {
      // if (item.productId === productId && item.categoryId === categoryId) {
      if (item.productId === productId) {
        // Update the quantity of the matching item
        return { ...item, quantity: qty };
      }
      return item; // Keep other items unchanged
    });

    localStorage.setItem(cartKey, JSON.stringify(updatedCartData));
  };

  const getProductQuantity = (cartData, productId, merchantId, categoryId) => {
    // const cartKey = `cart_${merchantId}`;
    // const cartData = JSON.parse(localStorage.getItem(cartKey));

    const foundItem =
      cartData &&
      cartData.find((item) => {
        // return item.productId === product.id && item.categoryId === categoryId;
        return item.productId === productId;
      });
    // console.log(foundItem)
    return foundItem ? parseInt(foundItem.quantity) : 1;
  };

  const isDecodedData = (str) => {
    try {
      return atob(str);
    } catch (error) {
      return false;
    }
  };
  const isValidJSON = (str) => {
    try {
      return JSON.parse(str);
    } catch (error) {
      return false;
    }
  };
  const filterProductData = (productInfo, productData) => {
    let productWithInfo = [];
    for (let index = 0; index < productInfo.length; index++) {
      const element = productInfo[index];
      let categoryArray = productData[element.categoryId];
      if (categoryArray && categoryArray.length) {
        let product = categoryArray.find(
          (e) =>
            e.id === element.productId && e.show_type === element.show_type,
        );
        if (product) {
          productWithInfo.push({
            productId: element.productId,
            categoryId: element.categoryId,
            product,
          });
        }
      }
    }
    return productWithInfo;
  };

  const filterCartData = (cartData, varientData, cartProductDetails) => {
    // cartData  =>  local storage cart data [only contains product_id & category_id]
    // cartProductDetails  => cart product details (used new api for getting product details by product id's)
    // varientData  => all the variant products inside localstorage cart dataset, filtered & setting them in here with its product details
    let productWithVarient = [];

    for (let index = 0; index < cartData.length; index++) {
      const element = cartData[index];

      let product = cartProductDetails?.find((e) => e.id === element.productId);
      if (product) {
        if (element.varients.length >= 1) {
          function processVariants(variants) {
            let result = '';

            for (let i = 0; i < variants.length; i++) {
              if (i > 0) {
                result += '/';
              }
              result += variants[i].varientVl;
            }

            return result || 'else';
          }

          let seletectedvar =
            Array.isArray(varientData) &&
            varientData.find((e) => {
              const res =
                e !== null &&
                e &&
                e.product_id === element.productId &&
                e.variant === processVariants(element.varients);
              return res;
            });

          seletectedvar !== undefined &&
            productWithVarient.push({
              cartProductId: element.cartProductId,
              productId: element.productId,
              categoryId: element.categoryId,
              varients: element.varients,
              allVarients: element.allVarients,
              variantId: seletectedvar.id,
              selectedQuantity: element.quantity,
              maxQuantity: seletectedvar.quantity,
              price: element.quantity * seletectedvar.price,
              comparePrice: element.quantity * seletectedvar.compare_price,
              isOutOfStock: element.isOutOfStock ? element.isOutOfStock : false,
              msg: element.msg ? element.msg : '',
              pqLimitReached: element.pqLimitReached
                ? element.pqLimitReached
                : false,
              product,
              taxesAplicable: product?.other_taxes?.split(','),
            });
        } else {
          productWithVarient.push({
            cartProductId: element.cartProductId,
            productId: element.productId,
            categoryId: element.categoryId,
            allVarients: element.allVarients,
            varients: element.varients,
            selectedQuantity: element.quantity,
            maxQuantity: product.quantity,
            price: element.quantity * product.price,
            comparePrice: element.quantity * product.compare_price,
            isOutOfStock: element.isOutOfStock ? element.isOutOfStock : false,
            msg: element.msg ? element.msg : '',
            pqLimitReached: element.pqLimitReached
              ? element.pqLimitReached
              : false,
            product,
            taxesAplicable: product?.other_taxes?.split(','),
          });
        }
      }
    }

    return productWithVarient;
  };

  const removeProductFromCart = (cartItem, cartKey) => {
    const cartDataInLocalStorage = JSON.parse(localStorage.getItem(cartKey));
    // console.log('BEFORE: ', cartDataInLocalStorage);
    const updatedCartData = cartDataInLocalStorage?.filter(
      (item) => item.cartProductId !== cartItem.cartProductId,
    );
    // console.log('AFTER: ', updatedCartData);
    localStorage.setItem(cartKey, JSON.stringify(updatedCartData));
  };
  // update product price from cart in localstore
  const updateProductFromCart = (cartItem, cartKey, qty) => {
    const cartDataInLocalStorage = JSON.parse(localStorage.getItem(cartKey));
    const updatedCartData =
      Array.isArray(cartDataInLocalStorage) &&
      cartDataInLocalStorage?.length >= 1 &&
      cartDataInLocalStorage.map((item) => {
        if (
          item.cartProductId === cartItem.cartProductId &&
          item.productId === cartItem.productId &&
          item.categoryId === cartItem.categoryId
        ) {
          // Update the quantity of the matching item
          return { ...item, quantity: qty };
        }
        return item; // Keep other items unchanged
      });
    localStorage.setItem(cartKey, JSON.stringify(updatedCartData));
  };

  // to get the varient price and quantity we need to run api for all products who have varients and by varients we can get the price and quantity
  const filterVarientDataFromCart = (cartData, merchant) => {
    // console.log(cartData)
    const outputData = [];

    cartData &&
      cartData?.length >= 1 &&
      cartData?.forEach((item) => {
        if (item.varients.length > 0) {
          const product_id = parseInt(item.productId);
          const merchant_id = merchant;
          const variant =
            item.varients &&
            item.varients.length >= 1 &&
            item.varients.map((variant) => variant.varientVl).join('/');
          // const variant =
          // let v1, v2, v3;
          // if (item.varients.length >= 1) {
          //   // Assuming there are at most 3 varients
          //   v1 = item.varients[0]?.varientVl || "";
          //   v2 = item.varients[1]?.varientVl || "";
          //   v3 = item.varients[2]?.varientVl || "";
          // }
          outputData.push({
            merchant_id,
            product_id,
            variant,
          });
        }
      });

    const outputJson = outputData;
    return outputJson;
  };

  // varient transformer for single product page
  const varientTransformer = (originalObject) => {
    if (originalObject !== null && originalObject !== undefined) {
      const transformedArray = [];

      for (let i = 1; i <= 3; i++) {
        const optKey = `options${i}`;
        const optValKey = `optionsvl${i}`;

        const obj = {
          [optKey]: originalObject && originalObject[optKey],
          [optValKey]:
            originalObject && originalObject[optValKey]
              ? originalObject[optValKey].split(',')
              : [],
        };

        transformedArray.push(obj);
      }
      return transformedArray;
    }
    return 0;
  };
  // calculate total price for cart checkout price
  const calculateTotalPrice = (cartData) => {
    const total = cartData.reduce((acc, product) => {
      return parseFloat(acc) + parseFloat(product.price);
    }, 0);
    return total;
  };

  const handleGetVariData = (selectedVarient, VarientData) => {
    // console.log(selectedVarient)
    // console.log(VarientData)
    const foundItem =
      VarientData &&
      VarientData.find((item) => {
        return item.variant === selectedVarient;
      });
    // console.log(foundItem)
    return foundItem;
  };

  const handleImagesArray = (filenames) => {
    if (filenames && typeof filenames === 'string') {
      const filenamesArray = filenames.split(',');
      if (filenamesArray.length >= 1) {
        return filenamesArray;
      }
    }
    return [];
  };

  const getCurrentDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  };

  const JsonItem = (
    cartData,
    taxDetailApiData,
    allCategories,
    percentageDeducCoupon,
    percentageDeducLoy,
  ) => {
    // console.log('allCategories: ', allCategories);
    let category = (id) => {
      // console.log('id: ', id);
      const categoryId = id.split(',')[0] ? id.split(',')[0] : id;
      // console.log('first categoryId: ', categoryId);
      const foundCategory =
        allCategories &&
        allCategories.find((item) => item.collection_id == categoryId);
      // console.log('foundCategory: ', foundCategory);
      return foundCategory?.collection_title;
    };
    let createFullName = (productName, varientArray) => {
      // console.log(varientArray);
      // console.log(productName);
      //  "test 111-small-green-test2"

      if (varientArray.length >= 1) {
        // const temp = varientArray
        //   .map(({ varientName, varientVl }) => `${productName}-${varientVl}`)
        //   .join('-');

        const temp = varientArray
          .map(({ varientVl }) => `${varientVl}`)
          .join('-');

        const fullName = `${productName}-${temp}`;

        return fullName;
      } else {
        return productName;
      }
    };
    let createVarientTitle = (varientArray) => {
      //"small/green/test2"
      return varientArray.map(({ varientVl }) => `${varientVl}`).join('/');
    };
    let createNote = (productName, varientArray) => {
      if (varientArray.length >= 1) {
        const name = `Name-${productName}`;
        const note = varientArray
          .map(({ varientName, varientVl }, index) => {
            return `${varientName}-${varientVl}`;
          })
          .join('~');

        const newNote = `${name}~${note}`;
        return newNote;
      } else {
        return `Name-${productName}`;
      }
    };
    let calculateDefaultTax = (tax, taxName, qty, price) => {
      const default_tax = tax?.split(',').map(Number);

      let matchedTax = false;
      let percentData = 0;
      for (let index = 0; index < default_tax?.length; index++) {
        const element = default_tax[index];
        const data = taxDetailApiData.find((item) => {
          // console.log('item: ', item);
          // console.log('element: ', element);
          // console.log(item.id == element && item.title === 'DefaultTax');
          return item.id == element && item.title === 'DefaultTax';
        });

        if (data) {
          matchedTax = true;
          percentData = data;
          break;
        }
      }

      // console.log('taxDetailApiData: ', taxDetailApiData);
      // console.log('matchedTax: ', matchedTax);
      // console.log('---------');
      if (matchedTax) {
        if (taxName === 'tax_rate') {
          return parseFloat(percentData.percent);
        } else {
          let amount = parseFloat(price);
          const taxApplied = (amount * parseFloat(percentData.percent)) / 100;
          return parseFloat(taxApplied);
        }
      } else {
        return 0;
      }
    };
    let calculateOtherTax = (tax, qty, price, taxName) => {
      let amount = parseFloat(price);
      const default_tax = tax?.split(',').map(Number);

      let sumtax = 0;
      let taxObject = {};

      for (let index = 0; index < default_tax?.length; index++) {
        const element = default_tax[index];
        const matchedTax = taxDetailApiData.find(
          (item) => item.id == element && item.title !== 'DefaultTax',
        );
        if (matchedTax) {
          // console.log(matchedTax);
          const taxApplied = (amount * parseFloat(matchedTax?.percent)) / 100;
          sumtax += parseFloat(taxApplied);
          taxObject[matchedTax?.title] = taxApplied;
        }
      }

      if (taxName === 'taxJson') {
        function isObjectEmpty(taxObject) {
          for (let key in taxObject) {
            if (taxObject.hasOwnProperty(key)) {
              return false; // Object is not empty
            }
          }
          return true; // Object is empty
        }

        return isObjectEmpty(taxObject) ? '' : JSON.stringify(taxObject);
      } else {
        return parseFloat(sumtax);
      }
    };
    const productprice = (price) => {
      // let newprice = parseFloat(price).toFixed(2);
      let newprice = parseFloat(price);
      let discount = 0;

      // only Coupon is applied and LP is not applied
      if (
        !isNaN(percentageDeducCoupon) &&
        percentageDeducCoupon > 0 &&
        !percentageDeducLoy &&
        percentageDeducLoy <= 0
      ) {
        // newprice -= (newprice * parseFloat(percentageDeducCoupon).toFixed(2)) / 100;
        newprice -= (newprice * parseFloat(percentageDeducCoupon)) / 100;
      }

      // console.log('1 new price: ', newprice);
      // console.log('discount: ', discount);

      // only LP is applied and Coupon is not applied
      if (
        !isNaN(percentageDeducLoy) &&
        (isNaN(percentageDeducCoupon) ||
          percentageDeducCoupon <= 0 ||
          !percentageDeducCoupon)
      ) {
        // const loyaltyDiscount = (newprice * parseFloat(percentageDeducLoy).toFixed(2)) / 100;
        const loyaltyDiscount =
          (newprice * parseFloat(percentageDeducLoy)) / 100;

        newprice -= loyaltyDiscount;
        discount += loyaltyDiscount;
      }

      // console.log('2 new price: ', newprice);
      // console.log('discount: ', discount);

      // IF both LP and coupon is applied
      if (
        !isNaN(percentageDeducLoy) &&
        percentageDeducLoy > 0 &&
        !isNaN(percentageDeducCoupon) &&
        percentageDeducCoupon > 0
      ) {
        let newPercent =
          parseFloat(percentageDeducCoupon) + parseFloat(percentageDeducLoy);

        // const loyaltyDiscount = (newprice * parseFloat(percentageDeducLoy).toFixed(2)) / 100;
        const loyaltyDiscount =
          (newprice * parseFloat(percentageDeducLoy)) / 100;

        // newprice -= (newprice * parseFloat(newPercent).toFixed(2)) / 100;
        newprice -= (newprice * parseFloat(newPercent)) / 100;
        discount += loyaltyDiscount;
      }

      // console.log('3 new price: ', newprice);
      // console.log('discount: ', discount);

      // console.log('percentageDeducLoy: ', percentageDeducLoy);
      // console.log('percentageDeducCoupon: ', percentageDeducCoupon);

      // return {
      //   newprice: parseFloat(newprice).toFixed(2),
      //   discount: parseFloat(discount).toFixed(2),
      // };

      return {
        newprice: parseFloat(newprice),
        discount: parseFloat(discount),
      };
    };

    const myJsonData = [];
    for (let index = 0; index < cartData.length; index++) {
      const element = cartData[index];

      // console.log('element?.price: ', element?.price);

      const price =
        productprice(
          parseFloat(element?.price / element?.selectedQuantity).toString() ??
            '',
        )?.newprice ?? 0;

      // console.log('price: ', price);
      const discount =
        productprice(
          parseFloat(element?.price / element?.selectedQuantity).toString() ??
            '',
        )?.discount ?? 0;

      // console.log('price: ', price);
      // console.log('discount: ', discount);

      const newElement = {
        img: element?.product?.media ?? '',
        product_id: element?.product?.id ?? '',
        name: element?.product?.title ?? '',
        variant_id: element?.variantId ?? '',
        variant_title: createVarientTitle(element?.varients) ?? '',
        category_id: element?.categoryId ?? '',
        cat_title: category(element?.categoryId) ?? '',
        fullname:
          createFullName(element?.product?.title, element?.varients) ?? '',
        note: createNote(element?.product?.title, element?.varients) ?? '',
        price: price ?? 0,
        cost_price: element?.product?.costperItem ?? '',
        qty: element?.selectedQuantity.toString() ?? 0,
        tax_rate:
          calculateDefaultTax(element?.product?.other_taxes, 'tax_rate') ?? '',
        lp_discount_amount: discount,
        tax:
          calculateDefaultTax(
            element?.product?.other_taxes,
            'tax',
            element?.selectedQuantity,
            price * element?.selectedQuantity ?? 0,
          ) ?? '',
        other_tax:
          calculateOtherTax(
            element?.product?.other_taxes,
            element?.selectedQuantity,
            price * element?.selectedQuantity ?? 0,
            'taxCalculate',
          ) ?? '',
        other_tax_desc:
          calculateOtherTax(
            element?.product?.other_taxes,
            element?.selectedQuantity,
            price * element?.selectedQuantity ?? 0,
            'taxJson',
          ) ?? '',
      };
      myJsonData.push(newElement);
    }
    // console.log('myJsonData: ', myJsonData);
    const convertedObject = {};
    myJsonData.forEach((item, index) => {
      convertedObject[index] = item;
    });
    // console.log('convertedObject: ', convertedObject);
    return convertedObject;
  };

  const place_Order = async (
    selectedDeliveryAdderess,
    couponData,
    accountInfoData,
    authuserData,
    storeDataState,
    finalAmountInfo,
    cartData,
    taxDetailApiData,
    totalTaxInfo,
    allCategories,
    paymentDPdata,
    lp_sc_data,
    discountAmt,
    percentageDeducCoupon,
    percentageDeducLoy,
    sendNotifications,
  ) => {
    const Items = JsonItem(
      cartData,
      taxDetailApiData,
      allCategories,
      percentageDeducCoupon,
      percentageDeducLoy,
    );

    const calculateDefaultTax = (taxName) => {
      if (taxName === 'default') {
        // console.log(totalTaxInfo['DefaultTax']);

        return (
          parseFloat(
            totalTaxInfo &&
              totalTaxInfo['DefaultTax'] &&
              totalTaxInfo['DefaultTax'],
          ) || 0
        );
      } else {
        if (totalTaxInfo['DefaultTax'] !== undefined) {
          const matchingItem = taxDetailApiData.find(
            (item) => item.title === 'DefaultTax',
          );
          return matchingItem ? parseFloat(matchingItem.percent) : 0;
        }
      }
    };
    const calculateOtherTax = () => {
      const sumOfOtherTaxes = Object.keys(totalTaxInfo)
        .filter((key) => key !== 'DefaultTax')
        .reduce((sum, key) => sum + totalTaxInfo[key], 0);

      return parseFloat(sumOfOtherTaxes);
    };
    const calculateOtherTaxdesc = () => {
      const otherTaxInfo = Object.keys(totalTaxInfo)
        .filter((key) => key !== 'DefaultTax')
        .reduce((result, key) => {
          result[key] = totalTaxInfo[key];
          return result;
        }, {});

      if (
        typeof otherTaxInfo === 'object' &&
        Object.keys(otherTaxInfo).length === 0
      ) {
        return '';
      } else {
        // Handle other cases if needed
        return JSON.stringify(otherTaxInfo);
      }
    };

    const finalAmountIsAboveZero =
      parseFloat((finalAmountInfo?.grandTotal).toFixed(2)) > 0;

    const data = {
      merchant_id: storeDataState?.merchant_id ?? '',
      is_guest: isGuest === 'yes' ? 'yes' : 0,
      is_future:
        storeDataState?.future_ordering === '1' &&
        paymentDPdata?.pickUpLaterDate &&
        paymentDPdata?.pickupTime
          ? 1
          : 0,
      f_date: paymentDPdata?.pickUpLaterDate ?? '',
      f_time: paymentDPdata?.pickupTime ?? '',
      customer_id: authuserData?.id,
      customer_name: authuserData?.name,
      ccOrder:
        paymentDPdata?.paymentType === 'newcash' ||
        (!finalAmountIsAboveZero && parseFloat(finalAmountInfo.mytipamt) <= 0)
          ? 0
          : 1,
      cus_email: authuserData?.email,
      bill_fname: accountInfoData?.message?.f_name ?? '',
      bill_lname: accountInfoData?.message?.l_name ?? '',
      bill_phone: accountInfoData?.message?.phone ?? '',
      bill_address: accountInfoData?.message?.a_address_line_1 ?? '',
      bill_address_2: accountInfoData?.message?.a_address_line_2 ?? '',
      bill_city: accountInfoData?.message?.a_city ?? '',
      bill_state: accountInfoData?.message?.a_state ?? '',
      bill_zip: accountInfoData?.message?.a_zip ?? '',
      // id_card_no: accountInfoData?.message?.i_card_number ?? '',
      // id_card_type: accountInfoData?.message?.i_card_type ?? '',
      // id_card_dob: accountInfoData?.message?.i_card_dob ?? '',
      // id_card_expiry: accountInfoData?.message?.i_card_ex_date ?? '',
      id_card_no:
        isGuest !== 'yes'
          ? accountInfoData?.message?.i_card_number ?? ''
          : JSON.parse(localStorage.getItem('guestDelivery_address'))
              .card_number,
      id_card_type:
        isGuest !== 'yes'
          ? accountInfoData?.message?.i_card_type ?? ''
          : JSON.parse(localStorage.getItem('guestDelivery_address'))
              .cc_id_card_Type,
      id_card_dob:
        isGuest !== 'yes'
          ? accountInfoData?.message?.i_card_dob ?? ''
          : JSON.parse(localStorage.getItem('guestDelivery_address'))
              .dateOfBirth,
      id_card_expiry:
        isGuest !== 'yes'
          ? accountInfoData?.message?.i_card_ex_date ?? ''
          : JSON.parse(localStorage.getItem('guestDelivery_address'))
              .expiry_date,

      id_card_front_img:
        isGuest !== 'yes'
          ? accountInfoData?.message?.i_card_front_img ?? ''
          : localStorage.getItem('imageName'),
      del_fname:
        selectedDeliveryAdderess?.billing === 'yes'
          ? selectedDeliveryAdderess?.address?.f_name ?? ''
          : selectedDeliveryAdderess?.address?.d_f_name ?? '',
      del_lname:
        selectedDeliveryAdderess?.billing === 'yes'
          ? selectedDeliveryAdderess?.address?.l_name ?? ''
          : selectedDeliveryAdderess?.address?.d_l_name ?? '',
      del_phone:
        selectedDeliveryAdderess?.billing === 'yes'
          ? selectedDeliveryAdderess?.address?.phone ?? ''
          : selectedDeliveryAdderess?.address?.d_mobile ?? '',
      del_address:
        selectedDeliveryAdderess?.billing === 'yes'
          ? selectedDeliveryAdderess?.address?.a_address_line_1 ?? ''
          : selectedDeliveryAdderess?.address?.a_address_line_1 ?? '',
      del_address_2:
        selectedDeliveryAdderess?.billing === 'yes'
          ? selectedDeliveryAdderess?.address?.a_address_line_2 ?? ''
          : selectedDeliveryAdderess?.address?.a_address_line_2 ?? '',
      del_city:
        selectedDeliveryAdderess?.billing === 'yes'
          ? selectedDeliveryAdderess?.address?.a_city ?? ''
          : selectedDeliveryAdderess?.address?.a_city ?? '',
      del_state:
        selectedDeliveryAdderess?.billing === 'yes'
          ? selectedDeliveryAdderess?.address?.a_state ?? ''
          : selectedDeliveryAdderess?.address?.a_state ?? '',
      del_zip:
        selectedDeliveryAdderess?.billing === 'yes'
          ? selectedDeliveryAdderess?.address?.a_zip ?? ''
          : selectedDeliveryAdderess?.address?.a_zip ?? '',
      orderMethod: storeDataState?.orderMethod ?? '',
      coupon_name: couponData?.name ?? '',
      discount_amt: parseFloat(finalAmountInfo.couponPrice) ?? '',
      coupon_id: couponData?.id ?? '',
      tip_amt: finalAmountInfo?.mytipamt,
      convfee_amt: finalAmountInfo?.conFee,
      delfee_amt: finalAmountInfo?.deliveryCharge
        ? finalAmountInfo?.deliveryCharge
        : 0,
      sub_total: parseFloat(finalAmountInfo?.subTotal),
      tax: calculateDefaultTax('default'),
      tax_rate: calculateDefaultTax('defaultPercent'),
      other_tax: calculateOtherTax() ?? '',
      other_tax_desc: calculateOtherTaxdesc() ?? '',
      grand_total: parseFloat(finalAmountInfo?.grandTotal) ?? 0,
      sms_notify: sendNotifications ? 'on' : 'off',
      cc_number:
        paymentDPdata?.newCard?.cardnumber !== ''
          ? paymentDPdata?.newCard?.cardnumber
          : paymentDPdata?.selectedCard?.ccnum4last,
      cc_month:
        paymentDPdata?.newCard?.expiredate !== ''
          ? paymentDPdata?.newCard?.expiredate?.split(' ')[0]
          : paymentDPdata?.selectedCard?.expires?.substring(0, 2),
      cc_year:
        paymentDPdata?.newCard?.expiredate !== ''
          ? paymentDPdata?.newCard?.expiredate?.split(' ')[1]?.substring(2)
          : paymentDPdata?.selectedCard?.expires?.substring(2, 5),
      cc_cvv:
        paymentDPdata?.newCard?.cvv !== '' ? paymentDPdata?.newCard?.cvv : '',
      radiocc: paymentDPdata?.selectedCard?.key ?? '',
      // credit card key
      savecard: paymentDPdata?.newCard?.saveFuture === true ? 1 : 0,
      items: JSON.stringify(Items),
      enable_loyalty: lp_sc_data?.enable_loyalty,
      store_credit_amt: lp_sc_data?.store_credit_amt,
      spend_loyality_amount: lp_sc_data?.spend_loyality_amount,
      spend_loyality_points: lp_sc_data?.spend_loyality_points,
      total_lp_apply_amt: isNaN(lp_sc_data?.total_lp_apply_amt)
        ? ''
        : lp_sc_data.total_lp_apply_amt,
      total_lp_earn_amt: isNaN(lp_sc_data?.total_lp_earn_amt)
        ? ''
        : lp_sc_data.total_lp_earn_amt,
      // total_lp_earn_amt: totalLPEarnedAmount,
    };

    // console.log('data: ', data);

    try {
      const response = await axios.post(BASE_URL_SANDBOX + SAVE_ORDER, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response) {
        // console.log('pri', response);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  const formatTimeRange = (open_time, close_time) => {
    const openTimes = open_time.split(',');
    const closeTimes = close_time.split(',');

    // Initialize an array to store the formatted time ranges
    const formattedTimeRanges = [];

    // Iterate through the arrays and format the time ranges
    for (let i = 0; i < openTimes.length; i++) {
      const openTime = openTimes[i].trim();
      const closeTime = closeTimes[i].trim();

      // Parse the time strings into Date objects for easier manipulation
      const openDate = new Date(`2000-01-01T${openTime}`);
      const closeDate = new Date(`2000-01-01T${closeTime}`);

      // Adjust the date for closing time if it's before the opening time
      if (closeDate < openDate) {
        closeDate.setDate(closeDate.getDate() + 1);
      }

      // Format the time in HH:mm AM/PM format
      const formattedOpenTime = openDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const formattedCloseTime = closeDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });

      // Add the formatted time range to the array
      formattedTimeRanges.push({
        open_time: formattedOpenTime,
        close_time: formattedCloseTime,
      });
    }

    return formattedTimeRanges;
  };

  // checking if image is available or not
  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;
    let result = false;

    if (img.complete) {
      result = true;
    } else {
      img.onload = () => {
        result = true;
      };

      img.onerror = () => {
        result = false;
      };
    }
    // console.log('image result: ', result);
    return result;
  }

  return {
    checkIfImageExists,
    getCurrentDateTime,
    handleGetVariData,
    filterVarientDataFromCart,
    calculateTotalPrice,
    formatDate,
    updateProductFromCart,
    removeProductFromCart,
    filterCartData,
    varientTransformer,
    filterProductData,
    isValidJSON,
    isDecodedData,
    getProductQuantity,
    UpdateQuantity,
    checkInCart,
    AddtoCart,
    validateEmail,
    validatePassword,
    validateOldPassword,
    validatePhoneNumber,
    validateConfrimPassword,
    validateFirstName,
    validateLastName,
    validateStreetAddress,
    validateZipCode,
    validateCity,
    validateState,
    validateIdNumber,
    formatCreditCardNumber,
    validateCardNumber,
    validateApartment,
    validateIDproof,
    validateExpiredate,
    validateDOB,
    validateImageProof,
    convertToIndianTime,
    percentOffItem,
    validatePasswordBlank,
    validateCaptcha,
    formatExireDate,
    getImageExtension,
    validateCoupon,
    handleImagesArray,
    place_Order,
    JsonItem,
    validateCardExpiredate,
    validateCvv,
    formatTimeRange,
  };
};

export default Validation;
